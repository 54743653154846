import React, { useState, forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import color from '../color-css/color'
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete'
import ValidationIndicator from '../ValidationIndicator'
import img from '../../assets/images/powered_by_google_on_white.png'
import useOnclickOutside from 'react-cool-onclickoutside'
import Label from '../Label'
import LabelIcon from '../Label/LabelIcon'
import List from './List'
import { ScrollBar } from '../ScrollBarComponent'

export type Props = {
  onSelectedItemProp?: (wasSelected: boolean) => void
  inputFromProps?: string
  isValidated?: boolean
  handleAddressInfo?: (
    cityValue: string,
    state: string,
    postcode: string
  ) => void
  label: string
  placeholder: string
  handleAddressValue?: (address: string, isValidated: boolean) => void
  hasAddressValueChange?: (wasSelected: boolean) => void
}

interface InputField {
  inputSuccessAsBackgroundColor: boolean
  inputSuccessListener?: (isSucess: boolean) => boolean
}

const InputField = styled.input<InputField>`
  font-family: museo-sans, sans-serif;
  @media screen and (max-width: 690px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-left: 0.9375rem;
  }
  -webkit-appearance: none;
  width: 34rem;
  height: 1.1875rem;
  background-color: ${(props) =>
    props.inputSuccessAsBackgroundColor === true ? color.blue : color.grey};
  transition: background 0.5s ease-in-out;
  border: 1px solid ${color.white};
  outline: none;
  color: ${(props) =>
    props.inputSuccessAsBackgroundColor === true
      ? color.blue_17_percent
      : color.grey_dark};
  font-size: 1rem;
  padding: 0.75rem 5.375rem 0.6875rem 1.5625rem;
  border-radius: 0.625rem;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${color.grey_darker};
    font-family: museo-sans, sans-serif;
    font-size: 1rem;
  }
  &:focus {
    ::-webkit-input-placeholder {
      color: transparent;
    }
    ::-moz-placeholder {
      color: transparent;
    }
    border: 1px solid ${color.blue_darker};
  }
  margin-left: -0.125rem;
`

const HoveringWrapper = styled.div`
  margin-top: 0.875rem;
  &:hover ${InputField} {
    transition: border 0.5s ease-in-out;
    border: 1px solid ${color.blue_darker};
    border-radius: 0.625rem;
    cursor: pointer;
  }
`

interface DropdownContainer {
  isShow: boolean
}

const DropdownContainer = styled.div<DropdownContainer>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  position: absolute;
  margin-top: 0.8125rem;
  width: 40.9375rem;
  height: 14.0625rem;
  z-index: 11111111;
  @media screen and (max-width: 690px) {
    width: 100%;
    box-sizing: border-box;
    height: 12.875rem;
  }
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  };
  background: ${color.white} 0% 0% no-repeat padding-box;
  box-shadow: 0 0 3.125rem rgba(1,47,87,0.1);
  -ms-box-shadow: 0px 0px 3.125rem rgba(1,47,87,0.1);
  border-radius: 10px;
  ${ScrollBar}
  &:before {
    content: "";
    position: absolute;
    left: 47%;
    top: -2rem;
    width: 0;
    height: 0;
    border-top: 1.375rem solid transparent;
    border-right: 0.75rem solid transparent;
    border-bottom: 0.8125rem solid ${color.white};
    border-left: 0.75rem solid transparent;
`

const DropdownFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 3.9375rem;
  margin: 0 1.75rem;
  border-top: 1px solid ${color.grey_79_percent};
  @media screen and (max-width: 690px) {
    width: 80%;
    height: 2.5rem;
  }
`

const DropdownFooterContent = styled.div`
  font-family: museo-sans, sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 1rem;
  color: ${color.grey_darker};
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.625rem;
  @media screen and (max-width: 690px) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  @media screen and (max-width: 576px) {
    font-size: 0.8125rem;
    line-height: 0.875rem;
  }
`

const DropdownFooterImg = styled.span`
  margin: auto;
  margin-left: 0;
  margin-right: 0;
`

interface DropdownSuggestContent {
  value?: (input: string) => string
}

const DropdownSuggestContent = styled.span`
  &:focus {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent};
  }
`

const DropdownSuggestContentKey = styled.div`
  margin-bottom: 0.875rem;
  outline: none;
  @media screen and (max-width: 768px) {
    margin-bottom: 1.0625rem;
  }
  @media screen and (max-width: 690px) {
    margin-bottom: 1.1875rem;
  }
  &:hover ${DropdownSuggestContent} {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
  &:focus ${DropdownSuggestContent} {
    transition: color 0.5s ease-in-out;
    color: ${color.blue_17_percent};
    font-weight: bold;
  }
`

interface ShowSpinner {
  isShow: boolean
}

export const Spinner = styled.div<ShowSpinner>`
  display: ${(props) => (props.isShow ? 'block' : 'none')};
  background-color: transparent;
  border: 5px solid ${color.blue};
  opacity: 0.9;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-radius: 100px;
  top: 3.4375rem;
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  position: absolute;
  left: 45%;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-name: spinoffPulse;
  -moz-animation-iteration-count: infinite;
  -o-animation-duration: 1s;
  -o-animation-timing-function: linear;
  -o-animation-name: spinoffPulse;
  -o-animation-iteration-count: infinite;
  animation-name: spinoffPulse;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spinoffPulse {
    0%,
    0.01% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const NoResultString = styled.div`
  padding: 0 7.25rem 0 1.75rem;
  margin-top: 2rem;
  font-weight: 500;
  color: #757575;
  font-style: italic;
  line-height: 1.5625rem;
  animation-name: fade;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    padding-left: 1.5625rem;
  }
  @media screen and (max-width: 576px) {
    padding: 0 1.25rem 0 1.5625rem;
  }
`

interface Wrapper {
  inputSuccessListener?: (isSucess: boolean) => boolean
}
const Wrapper = styled.div<Wrapper>`
  display: block;
`

type Ref = HTMLInputElement

const PlacesAutocomplete = forwardRef<Ref, Props>((props: any, aRef) => {
  const {
    inputFromProps,
    isValidated,
    onSelectedItemProp,
    handleAddressValue,
    handleAddressInfo,
    label,
    placeholder,
    hasAddressValueChange,
  } = props
  const [inputSuccess, setInputSuccess] = useState(false)
  const [visibility, setVisibility] = useState(false)
  const [showingDropdown, setShowingDropdown] = useState(false)
  const [clickedOutsideState, setClickedOutside] = useState(false)
  const [validatorMsg, setValidatorMsg] = useState('')
  const [labelIconState, setLabelIconStateState] = useState(true)
  const [postcodeArray, setPostcodeArray] = useState<any>([''])
  const [addressArray, setAddressArray] = useState([''])
  const [showingSpinner, setShowingSpinner] = useState(false)
  const [isAddressChosen, setIsAddressChosen] = useState(isValidated)
  const [hasInputChange, setInputChange] = useState(false)

  const [onNext, setOnNext] = useState(0)

  const {
    value,
    suggestions: { data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'au' },
    },
    debounce: 1500,
  })

  const handleInput = (e: any) => {
    setValue(e.target.value)
    e.target.value.length >= 2
      ? setShowingDropdown(true)
      : setShowingDropdown(false)
    setIsAddressChosen(false)
    setInputChange(true)
    setAddressArray([])
  }

  const handleSelect = (description: string) => {
    const streetaddress = description.substr(0, description.indexOf(','))
    streetaddress && handleAddressValue
      ? handleAddressValue(streetaddress, !!streetaddress)
      : {}
    let streetState = description.replace(streetaddress, '')
    streetState = streetState.replace(',', '')
    streetState = streetState.substr(1, streetState.length)
    const postcode = streetState.slice(-4)
    streetState = streetState.replace(postcode, '')
    const stateOrTerritory: any = streetState.slice(-4).trim()
    let city: any = streetState.replace(streetState.slice(-4).trim(), '')
    city = city.substr(0, city.length - 2)
    handleAddressInfo ? handleAddressInfo(city, stateOrTerritory, postcode) : {}
    setValue(streetaddress)
    setShowingDropdown(false)
    setIsAddressChosen(true)
  }

  function getGeocodeFromAPI(input: string) {
    return new Promise((resolve) => {
      getGeocode({ address: input })
        .then((results) => getZipCode(results[0], false))
        .then((zipCode) => {
          resolve(zipCode)
        })
    })
  }

  function renderGeocode(geocode: any) {
    setPostcodeArray(geocode)
  }

  function loadGeocode() {
    const allGeocode: any = []
    data.forEach((each) => {
      allGeocode.push(getGeocodeFromAPI(each.description))
    })
    Promise.all(allGeocode).then((geocode) => {
      renderGeocode(geocode)
    })
  }

  useEffect(() => {
    loadGeocode()
  }, [data])

  useEffect(() => {
    const array: string[] = []
    for (let i = 0; i < data.length; i++) {
      const test = data[i].description + postcodeArray[i]
      array.push(test)
    }
    setAddressArray(array)
  }, [postcodeArray])

  useEffect(() => {
    if (isAddressChosen !== true) {
      if (addressArray[0] !== undefined) setShowingSpinner(false)
      else {
        setShowingSpinner(true)
        const timer = setTimeout(() => {
          setShowingSpinner(false)
        }, 2500)
        return () => clearTimeout(timer)
      }
    } else {
      setShowingDropdown(false)
    }
  }, [addressArray, isAddressChosen])

  const RegardlessSplitter = '+[(?=,)|(?= )|(?=.)]+'

  const arrayRefs: HTMLDivElement[] = []

  const renderSuggestions = addressArray.map((each: string, i: number) => {
    each = each.replace(', Australia', ' ')
    const findSuggestionIsAddress = each.substring(0, 1)
    if (!isNaN(Number(findSuggestionIsAddress.toString()))) {
      if (value !== undefined) {
        const valueMapper = value.replace('-', '\\-').split(' ')
        const lastValue = valueMapper[valueMapper.length - 1]

        const level2 = valueMapper.map(
          (eachValue) => '[' + eachValue + ']' + RegardlessSplitter
        )
        level2[level2.length - 1] = lastValue

        const pattern = level2.join('')
        const regex = new RegExp(pattern, 'gi')
        const suggestString = each.match(regex)
        let parseSuggestStringToString = JSON.stringify(suggestString)
        parseSuggestStringToString = parseSuggestStringToString.replace(
          '["',
          ''
        )

        const suggestResult = parseSuggestStringToString.substring(
          0,
          parseSuggestStringToString.indexOf('"')
        )

        const suggestHighlight =
          each.substring(0, suggestResult.length) || undefined
        const suggest = each.substring(suggestResult.length, each.length)

        return suggestHighlight ? (
          <DropdownSuggestContentKey
            tabIndex={0}
            onKeyDown={(e: any) => {
              if ((e.key === 'Tab' && !e.shiftKey) || e.key === 'ArrowDown') {
                if (onNext < arrayRefs.length - 1) {
                  e.preventDefault()
                  setOnNext(onNext + 1)
                  arrayRefs[onNext + 1]?.focus()
                } else {
                  setOnNext(0)
                  setShowingDropdown(false)
                }
              }
              if ((e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowUp') {
                if (onNext > 0) {
                  e.preventDefault()
                  setOnNext(onNext - 1)
                  arrayRefs[onNext - 1]?.focus()
                } else {
                  setOnNext(0)
                  setShowingDropdown(false)
                }
              }
              if (e.key === 'Enter') {
                e.preventDefault()
                handleSelect(e.target.textContent)
                setShowingDropdown(false)
              }
            }}
            key={i}
            onClick={() => handleSelect(each)}
            ref={(ref: any) => {
              arrayRefs.push(ref)
            }}
          >
            <DropdownSuggestContent>
              <strong>
                {suggestHighlight}
              </strong>
              {suggest}
            </DropdownSuggestContent>
          </DropdownSuggestContentKey>
        ) : undefined
      }
    }
  })

  const onValidate = (e: any) => {
    // check that the user hasn't entered only whitespace
    const inputGetter = e.target.value
    const whitespaceRegex = /^\s*$/gi
    const isValid =
      !!inputGetter && Boolean(inputGetter.match(whitespaceRegex) === null)
    setInputSuccess(() => isValid)
    setVisibility(true)
    setInputChange(false)
    onSelectedItemProp ? onSelectedItemProp(true) : {}
    handleAddressValue ? handleAddressValue(inputGetter, isValid) : {}
  }

  useEffect(() => {
    if (
      inputFromProps !== undefined &&
      inputFromProps !== '' &&
      isValidated !== false
    ) {
      inputFromProps ? setValue(inputFromProps) : {}
      setVisibility(isValidated)
      setInputSuccess(isValidated)
      onSelectedItemProp ? onSelectedItemProp(isValidated) : {}
      handleAddressValue ? handleAddressValue(inputFromProps, isValidated) : {}
    }
    if (inputFromProps === '') {
      setValue(inputFromProps)
      setInputSuccess(false)
    }
  }, [inputFromProps, isValidated])

  useEffect(() => {
    onSelectedItemProp ? onSelectedItemProp(inputSuccess) : {}
    inputSuccess
      ? setValidatorMsg('Thanks')
      : setValidatorMsg('Enter your address')
  }, [inputSuccess])

  useEffect(() => {
    hasAddressValueChange ? hasAddressValueChange(hasInputChange) : {}
  }, [hasInputChange])

  const handleClickOutsideRef = useOnclickOutside(
    () => {
      setShowingDropdown(false)
      setClickedOutside(!clickedOutsideState)
    },
    { eventTypes: ['click'] }
  )

  useEffect(() => {
    if (visibility === true && inputSuccess === false) {
      setLabelIconStateState(false)
    } else {
      setLabelIconStateState(true)
    }
  }, [inputSuccess, visibility])

  return (
    <Wrapper>
      <Label>
        {label} 
        {' '}
        <LabelIcon iconStates={labelIconState}>
          {'  '}
*
        </LabelIcon>
      </Label>
      <HoveringWrapper>
        <InputField
          name="address"
          placeholder={placeholder}
          value={value}
          onChange={handleInput}
          onBlur={(event) => onValidate(event)}
          inputSuccessAsBackgroundColor={inputSuccess}
          ref={aRef}
          type="text"
          autoComplete="none"
          autoCorrect="off"
          onKeyDown={(event: any) => {
            if (showingDropdown) {
              if (['Tab', 'ArrowDown'].includes(event.key)) {
                event.preventDefault()
                event.stopPropagation()
                arrayRefs[onNext]?.focus()
              }
              if (event.key === 'Enter') {
                event.preventDefault()
                setShowingDropdown(false)
                onValidate(event)
              }
            }
          }}
          inputSuccessListener={
            props.onInputSucessProp ? props.onInputSucessProp(inputSuccess) : {}
          }
        />
      </HoveringWrapper>
      <DropdownContainer
        isShow={showingDropdown}
        ref={handleClickOutsideRef}>
        <Spinner isShow={showingSpinner} />
        {showingSpinner === false && renderSuggestions[0] === undefined && (
          <NoResultString>
            There are no suggested results based on your search.
            <br />
            Continue typing and press enter to manually add your address.
          </NoResultString>
        )}
        {renderSuggestions[0] !== undefined && (
          <List>
            { renderSuggestions }
          </List>
        )}
        <DropdownFooter>
          <DropdownFooterContent>
            Can’t find your address? Continue typing
          </DropdownFooterContent>
          <DropdownFooterImg>
            <img
              src={img}
              style={{ marginBottom: '0' }} />
          </DropdownFooterImg>
        </DropdownFooter>
      </DropdownContainer>
      {validatorMsg !== '' && (
        <ValidationIndicator
          isCorrect={inputSuccess}
          {...{ message: validatorMsg, visibility }}
        />
      )}
    </Wrapper>
  )
})

PlacesAutocomplete.displayName = 'PlacesAutocomplete'

export default PlacesAutocomplete
